import styled from 'styled-components'

import background from '../images/background.jpg'
import lineaCuadrado from '../images/linea-cuadrado.png'
import coverImage from '../images/cover-image.jpg'
import lineaTriangulo from '../images/linea-triangulo.png'


export const PageCover = styled.div`
    background-image: url(${background});
    min-height: 100vh;
    padding-top: 40px;

    @media ( max-width: 900px ) {
      padding-top: 0 !important;
    }
`
export const GridBox = styled.div`
    display: grid;
    grid-template-areas: 
    "header header header"
    "colleft colcontent colright"
    "footer footer footer";
    grid-template-columns: 50px 1fr 50px;
    grid-template-rows: 50px 1fr 50px;
    max-width: 1240px;
    margin: 0 auto;
    
    @media ( max-width:900px ) {
      grid-template-areas: "header" "colcontent" "colleft" "colright" "footer";
      grid-template-columns: 100%;
      grid-template-rows: 0 1fr 0 0 0;
    }
`
export const ItemHeader = styled.div`
    grid-area: header;
    padding: 0 50px;
    background-image: url(${lineaCuadrado});
    background-repeat: no-repeat;
    background-position: 90% 50%;

    @media ( max-width: 900px ) {
      display: none;
    }
`
export const ItemRight = styled.div`
    grid-area: colright;

    @media ( max-width: 900px ) {
        display: none;
    }
`
export const ItemContent = styled.div`
    grid-area: colcontent;
    background-image: url(${coverImage});
    background-blend-mode: multiply;
    background-color: bisque;
    background-repeat: no-repeat;
    background-size: cover;
    height: 600px;

    @media ( max-width: 900px) {
      order: 2;
      background-position: center top !important;
      height: 100vh;
      padding: 10px;
      padding-top: 30vh; 
    }
`

export const ItemContentHero = styled.div`
    margin-left: 50%;
    margin-top: 15%;


    @media ( max-width: 900px ) {
      margin: 0 !important;
    }
`
export const ItemFooter = styled.div`
    grid-area: footer;
    background-image: url(${lineaTriangulo});
    background-repeat: no-repeat;
    background-position: 10% 50%;

    @media ( max-width: 900px ) {
      display: none;
    }
`
export const ItemLeft = styled.div`
    grid-area: colleft;

    @media ( max-width: 900px) {
      order: 3;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translate(-50%);
    }
`
export const List = styled.ul`
    list-style: none;
    margin:0;
    padding: 0;
    z-index: 2;
    position: relative;
`
export const ListItem = styled.li`
    text-align: center;

    @media ( max-width: 900px ) {
        text-align: center;
        display: inline-grid;
        margin-left: 10px;
    }
`
export const Heading = styled.h2`
    font-weight: 700;
    font-size: 10vw;
    color: #faf0d6;
    line-height: 6rem;
    letter-spacing: -3px;

    @media ( min-width:1380px ) {
      font-size: 135px !important; 
    }

    @media ( max-width: 900px ) {
        text-align: center;
        font-size: 20vw !important;
        line-height: 4rem !important;
        margin-bottom: 3rem;
    }

    @media ( max-width: 320px ) {
      margin-bottom: 1rem;
    }
`
export const HeadingSpan = styled.span`
    display: block;
    font-size: 5vw;

    @media ( max-width: 900px) {
      font-size: 15vw !important;
    }
`
export const ItemLeftPar = styled.p`
    transform: rotate(-90deg);
    position: relative;
    width: 100vh;
    left: -26vw;
    top: 100px;
    opacity: 0.25;
    line-height: 2rem;
    z-index: 1;

    @media ( max-width: 1024px ) {
      left: -36vw !important;
    }

    @media ( max-width: 900px ) {
      display: none;
    }

`

export const ContentHeroDiv = styled.div`
    float: right;
    padding-right: 25px;
    width: 80%;

    @media ( max-width: 900px ) {
      padding: 0 !important;
      width: 100%;
    }
`
