import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import SEO from '../components/seo'
import { PageCover, GridBox, ItemHeader, ItemRight, ItemContent, ItemContentHero, ItemFooter, ItemLeft, List, ListItem, Heading, HeadingSpan, ItemLeftPar, ContentHeroDiv } from '../components/styled-index'

import logo from '../images/mdt.svg'
import facebook from '../images/fa-facebook-square.svg'
import instagram from '../images/fa-instagram.svg'

import '../components/layout.css'

const Button = styled(Link)`
    display: block;
    padding: 10px 20px;
    text-align: center;
    margin: 10px 0;
    text-decoration: none;
    border: 2px solid #faf0d6;
    width: 300px;
    color: #faf0d6;
    border-radius: 10px;
    margin: 10px auto 20px;
    background: transparent;
`

class IndexPage extends React.Component { 
  
  render(){

    return(
    <div>
      <PageCover>
        <GridBox>
          <SEO title="Home" keywords={[`mcKenzie`, `MDT`, `MDT Costa Rica`]} />
          <ItemHeader>
            <p style={{
              opacity: 0.25,
              lineHeight: `2rem`,
            }}>¿Hay algún momento en el día en el cuál no tengas dolor?</p>
          </ItemHeader>
          <ItemRight> &nbsp; </ItemRight>
          <ItemContent>
            <ItemContentHero>
              <Heading>
                <HeadingSpan>Dolor de </HeadingSpan>
                Espalda?
            </Heading>
              <ContentHeroDiv>
                <Button to="/page-2/">No Sufra Mas</Button>
                <img
                  style={{
                    paddingRight: 25,
                  }}
                  src={logo} alt="mdt-logo"
                />
              </ContentHeroDiv>
            </ItemContentHero>
          </ItemContent>
          <ItemFooter>
            <p style={{
              opacity: 0.25,
              lineHeight: `2rem`,
              textAlign: `right`,
              paddingRight: 50,
            }}>¿Asocias tu dolor con una actividad concreta?</p>
          </ItemFooter>
          <ItemLeft>
            <List>
              <ListItem>
                <a href="https://www.facebook.com/adriancamposfisioterapia.cr" target="_blank" rel="noopener noreferrer">
                  <img src={facebook} alt="mdt-facebook-page" className={`mdt-ico--social`} />
                </a>
              </ListItem>
              <ListItem>
                <a href="https://www.instagram.com/fisioadriancampos/" target="_blank" rel="noopener noreferrer">
                  <img src={instagram} alt="mdt-instagram-page" className={`mdt-ico--social`} />
                </a>
              </ListItem>
            </List>
            <ItemLeftPar>
              ¿Te encuentras mejor cuando te tumbas boca abajo?
            </ItemLeftPar>
          </ItemLeft>
        </GridBox>
      </PageCover>
    </div>
    )
  }
}

export default IndexPage
